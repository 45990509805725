import { UndefinedInitialDataOptions } from "@tanstack/react-query";
import { fetchContacts } from "../helpers/fetchContacts";

export const contactsQuery = {
  queryKey: ["contacts"],
  queryFn: async () => {
    const maxRetryAttempts = 5;
    const retryDelay = 5000;

    for (
      let retryAttempt = 0;
      retryAttempt < maxRetryAttempts;
      retryAttempt++
    ) {
      try {
        const contacts = await fetchContacts();
        if (contacts !== null) {
          return contacts;
        }
      } catch (error) {
        console.error("Unable to fetch contacts", error);
      }

      if (retryAttempt < maxRetryAttempts - 1) {
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
      }
    }

    return null;
  },
} as const satisfies UndefinedInitialDataOptions;
