import { dispatchCallFailure } from "@/components/callOverlays/CallOverlays";
import adapter from "webrtc-adapter";
import { updateCallsInDatabase } from "../calls/callUtils";
import { generateRandomString } from "../helpers/Utils";
import { getConfig } from "../helpers/config";
import { formatPhoneNumber } from "../helpers/formatPhoneNumber";
import { getLocalAccessToken, getLocalUser } from "../helpers/localstorage";
import {
  releaseAudioStream,
  releaseVideoCallStream,
} from "../helpers/mediaStream";
import {
  WebRTCAnswerNotification,
  WebRTCCVONotification,
  WebRTCPauseNotification,
  WebRTCRingingNotification,
  WebRTCStatusUpdateNotification,
} from "../helpers/notificationChannel";
import { handleReloginMechanic } from "../hooks/useWebgwSubscription";
import NmsMessage from "../messaging/NmsMessage";
import { cleanPhoneNumber } from "../messaging/conversation/conversationUtils/phoneNumberUtils";
import TranscriptSender from "../voicebot/transcriptSender";
import VoiceBotManager from "../voicebot/voicebotManager";

const CALL_DIRECTION = {
  Undefined: "Undefined",
  Outgoing: "Outgoing",
  Incoming: "Incoming",
} as const;
type CALL_DIRECTION = (typeof CALL_DIRECTION)[keyof typeof CALL_DIRECTION];

export const CALL_STATE = {
  NoCall: "NoCall",
  Incoming: "Incoming",
  Outgoing: "Outgoing",
  Active: "Active",
  Hold: "Hold",
  Reject: "Reject",
} as const;
export type CALL_STATE = (typeof CALL_STATE)[keyof typeof CALL_STATE];

export const REJECT_CALL_CODE = 603;

type WrtcPayload = {
  calluri: string;
  callid: string;
  method:
    | "wrtc_call"
    | "wrtc_answer"
    | "wrtc_bye"
    | "wrtc_ringing"
    | "wrtc_answer"
    | "wrtc_reject"
    | "wrtc_cvo"
    | "wrtc_pause";
  sdp: string;
};

type CallStateChange = (
  callState: string,
  remote: string,
  isVideo: boolean
) => void;

const config: RTCConfiguration = {
  iceTransportPolicy: "all",
  iceCandidatePoolSize: 4,
  bundlePolicy: "balanced",
};

export const baseWebGwUrl = window._env_.WEB_GW_URL;
const TARGET_BITRATE = 2000;
const TARGET_FRAMERATE = 30;

export default class Webrtc {
  private peerConnection!: RTCPeerConnection;
  private remoteNumber!: string;
  private remoteSDP!: string;
  private callDirection: CALL_DIRECTION = CALL_DIRECTION.Undefined;
  private callState: CALL_STATE = CALL_STATE.NoCall;
  private transformVideo: number = 0;
  private callId: string = "";
  private browserSupportsCvo: boolean = false; // Will be set to true if local offer has CVO extension
  private microphoneAudioMedia: MediaStream | undefined;
  private transcriptSender: TranscriptSender;
  private voiceBotManager: VoiceBotManager;

  // callbacks
  private onCvoChanged!: any;
  private videoRef!: any;
  private audioRef!: any;
  private onCallStateChange!: CallStateChange;
  private onStatUpdated: any;
  private onPause!: any;

  private static readonly LOG_PREFIX = "Webrtc: ";
  private static readonly REGEX_VIDEO_ON_SDP = /m=video [1-9][0-9]*/;

  constructor() {
    this.remoteNumber = "";
    this.remoteSDP = "";
    this.transcriptSender = new TranscriptSender();
    this.voiceBotManager = new VoiceBotManager(this.transcriptSender, this);
  }

  public setVideoRef(videoRef: any): any {
    console.log("Setting video ref -> ", videoRef);
    if (this.videoRef && this.videoRef.current) {
      (this.videoRef.current as HTMLVideoElement).srcObject = null;
    }
    this.videoRef = videoRef;
  }

  public setAudioRef(audioRef: any): any {
    console.log("Setting audio ref -> ", audioRef);
    if (this.audioRef && this.audioRef.current) {
      (this.audioRef.current as HTMLVideoElement).srcObject = null;
    }
    this.audioRef = audioRef;
  }

  public getCallState() {
    return this.callState;
  }

  public getcallId() {
    return this.callId;
  }

  public startLiveTranscription(): boolean {
    // live transcription will only take incoming since we don't really care about seeing what we say.
    return this.voiceBotManager.startLiveRecording() ?? false;
  }

  public stopLiveTranscription() {
    this.voiceBotManager.stopLiveRecording();
  }

  public getVoiceBotManager(): VoiceBotManager {
    return this.voiceBotManager;
  }

  public startCallTranscription(): boolean {
    return this.voiceBotManager.startCallTranscription(this.callId) ?? false;
  }

  public stopCallTranscription() {
    this.voiceBotManager.stopCallTranscription(this.callId);
  }

  public setCallStateChangeCallback(onCallStateChange: CallStateChange): any {
    this.onCallStateChange = onCallStateChange;
  }

  public setOnStatUpdated(onStatUpdatedCallback: any): any {
    this.onStatUpdated = onStatUpdatedCallback;
  }

  public setWebrtcCallback(onCvoChanged: any): any {
    this.onCvoChanged = onCvoChanged;
  }

  public setWebrtcPausedCallback(pause: boolean) {
    this.onPause = pause;
  }

  private getCallId() {
    if (this.callDirection === CALL_DIRECTION.Incoming) {
      return this.callId;
    } else {
      return `CALLID-${generateRandomString(25)}`;
    }
  }

  private async finalizeSdp() {
    const user = getLocalUser();
    if (!user) {
      console.error("No user logged in");
      return;
    }
    let acsConfig = await getConfig();
    if (!acsConfig) {
      console.error("No config");
      acsConfig = await getConfig(true);
    }
    let Sdp = "";
    try {
      Sdp = this.peerConnection.localDescription?.sdp ?? "";
    } catch (e) {
      // ignore
    }
    if (this.callDirection === CALL_DIRECTION.Incoming && Sdp) {
      if (
        !Sdp.includes("\r\nm=video ") &&
        this.remoteSDP?.includes("\r\nm=video ")
      ) {
        Sdp = Sdp + "m=video 0 UDP/TLS/RTP/SAVPF 0\r\n";
      } else {
        Sdp = Sdp.replace(
          /m=video (\d)+ ([^ ]+) (\d+)(.|\r\n)+a=recvonly(.|\r\n)+/,
          "m=video 0 $2 $3"
        );
        Sdp = Sdp.replace("\r\na=inactive", "");
      }
    }

    Sdp = Sdp?.replace(/ VP/g, " PV");
    Sdp = Sdp?.replace(/ AV/g, " VA");
    Sdp = Sdp?.replace(/ rtx/g, " xtr");
    // Sdp = Sdp?.replace(/ H264/g, " T264");
    console.log("final SDP is -> ", Sdp);

    console.log("this.remoteNumber -> ", this.remoteNumber);
    const payload = {
      calluri: await formatPhoneNumber(this.remoteNumber, "SIP"),
      callid: this.getCallId(),
      method: `${
        this.callDirection === CALL_DIRECTION.Outgoing
          ? "wrtc_call"
          : "wrtc_answer"
      }`,
      sdp: Sdp,
    };

    let res;
    try {
      res = await fetch(
        new URL(
          `/webrtc/v1/${cleanPhoneNumber(
            user
          )}/wrtc?access_token=${getLocalAccessToken()}`,
          baseWebGwUrl
        ),
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );
    } catch (err) {
      handleReloginMechanic(false);
    }

    if (!res || !res.ok) {
      if (res?.status === 403 || res?.status === 500) {
        handleReloginMechanic(false);
      }

      dispatchCallFailure(
        `Call with ${this.remoteNumber} could not be established.${
          res ? `Status: ${res.status}` : ""
        }`
      );

      if (res) {
        console.error(
          `Error calling ${this.remoteNumber}. Status: ${res.status}`
        );
      }
    }
  }

  public makeAudioCall(remoteNumber: string, stream: any) {
    console.log("makeAudioCall ", remoteNumber);
    this.makeCall(remoteNumber, stream);
  }

  public makeVideoCall(remoteNumber: string, stream: any) {
    console.log("makeVideoCall ", remoteNumber);
    this.makeCall(remoteNumber, stream);
  }

  private callDurationTimer: ReturnType<typeof setTimeout> | null = null;
  private hangupCallTimer: ReturnType<typeof setTimeout> | null = null;
  private statInterval: ReturnType<typeof setInterval> | null = null;

  private makeCall(remoteNumber: string, stream: any) {
    // In case the number is not international, this will return the one with + if already known
    this.remoteNumber = formatPhoneNumber(remoteNumber, "E164");

    this.callDirection = CALL_DIRECTION.Outgoing;

    if (stream === null) {
      console.error("Stream is null");
      return;
    }

    this.stopClose();
    this.peerConnection = new RTCPeerConnection(config);

    this.peerConnection.ontrack = this.onTrack.bind(this);
    this.peerConnection.onconnectionstatechange =
      this.onConnectionStateChange.bind(this);
    this.peerConnection.oniceconnectionstatechange =
      this.onIceConnectionStateChange.bind(this);
    this.peerConnection.onicegatheringstatechange =
      this.onIceGatheringStateChange.bind(this);
    this.peerConnection.onnegotiationneeded =
      this.onNegotiationNeeded.bind(this);

    stream.getVideoTracks().forEach((t) => {
      t.contentHint = "motion";
    });

    stream.getTracks().forEach((track: MediaStreamTrack) => {
      console.log(
        `==> kind:${track.kind}, id:${track.id}, name:${track.label}, contentHint:${track.contentHint}`
      );

      if (track.kind === "audio") {
        track = this.mergeStreamWithVoiceBot(stream);

        const audioMedia = new MediaStream();
        audioMedia.addTrack(track);
        this.microphoneAudioMedia = audioMedia;
      }

      this.peerConnection.addTrack(track, stream);
    });
    this.updateCallState(CALL_STATE.Outgoing);
    this.callDurationTimer = setTimeout(() => {
      if (this.callState === CALL_STATE.Outgoing) {
        this.hangupCall();
      }
    }, 120 * 1000);
  }

  // Voice bot needs to play a bip sound when recording, we need to combine the original stream with the one that will be used by the bot sound
  private mergeStreamWithVoiceBot(stream) {
    const audioContext = new AudioContext();
    const combinedMediaStream = audioContext.createMediaStreamDestination();
    const originalStreamSource = audioContext.createMediaStreamSource(stream);

    originalStreamSource.connect(combinedMediaStream);

    this.voiceBotManager.setCurrentMediaStream(
      audioContext,
      combinedMediaStream
    );

    return combinedMediaStream.stream.getAudioTracks()[0];
  }

  public answerCall(stream: MediaStream) {
    let sdp = this.remoteSDP;
    console.log("Received SDP -> " + sdp);
    this.callDirection = CALL_DIRECTION.Incoming;
    if (navigator.userAgent.includes("Chrome")) {
      //Chrome unsupported 0 setRemoteDescription
      sdp = sdp.replace(/m=video 0 (.|\r\n)+/, "");
    }

    this.stopClose();

    this.peerConnection = new RTCPeerConnection();
    this.peerConnection.ontrack = this.onTrack.bind(this);
    this.peerConnection.onconnectionstatechange =
      this.onConnectionStateChange.bind(this);
    this.peerConnection.oniceconnectionstatechange =
      this.onIceConnectionStateChange.bind(this);
    this.peerConnection.onicegatheringstatechange =
      this.onIceGatheringStateChange.bind(this);
    this.peerConnection.onnegotiationneeded =
      this.onNegotiationNeeded.bind(this);

    let offerToReceiveAudio = false,
      offerToReceiveVideo = false;
    stream.getVideoTracks().forEach((t) => {
      t.contentHint = "motion";
    });
    stream.getTracks().forEach((track) => {
      console.log(
        `==> kind:${track.kind}, id:${track.id}, name:${track.label}, contentHint:${track.contentHint}`
      );

      if (track.kind === "video") {
        offerToReceiveVideo = true;
        let videoStream = new MediaStream();
        videoStream.addTrack(stream.getVideoTracks()[0]);
        this.peerConnection.addTrack(track, videoStream);
      } else {
        offerToReceiveAudio = true;
        track = this.mergeStreamWithVoiceBot(stream);
        let audioStream = new MediaStream();
        audioStream.addTrack(track);
        this.peerConnection.addTrack(track, audioStream);
        this.microphoneAudioMedia = audioStream;
      }
    });
    // don't provide video from scratch otherwise webrtc will connect video
    // inactive for FF and 9 recv for Chrome instead replace in finalizeSdp
    if (!offerToReceiveVideo) {
      sdp = sdp.replace(/m=video (.|\r\n)+/, "");
    }

    sdp = this.removeLinesFromSdp(sdp);

    const remoteOffer = new RTCSessionDescription({ type: "offer", sdp: sdp });

    this.peerConnection
      .setRemoteDescription(remoteOffer)
      .then((_) => {
        let RTCAnswerOptions = {
          offerToReceiveVideo: offerToReceiveVideo,
          offerToReceiveAudio: offerToReceiveAudio,
        };
        console.log(
          "Set remote description with provided SDP, creating answer" +
            offerToReceiveVideo
        );
        return this.peerConnection.createAnswer(RTCAnswerOptions);
      })
      .then((answer) => {
        console.log("Created answer, setting local description" + answer?.sdp);

        let Sdp = answer.sdp;

        // When sending the SDP to the MT, we simply provide invalid codec types.
        // But when setting the local description, we actually remove those lines.
        Sdp = Sdp?.replace(/ VP/g, " PV");
        Sdp = Sdp?.replace(/ AV/g, " VA");
        Sdp = Sdp?.replace(/ rtx/g, " xtr");
        Sdp = this.removeLinesFromSdp(Sdp!);
        const localOffer = new RTCSessionDescription({
          type: answer?.type,
          sdp: Sdp,
        });
        this.peerConnection.setLocalDescription(localOffer);
        this.browserSupportsCvo ||=
          Sdp?.match(/a=extmap:.*urn:3gpp:video-orientation/g) !== null;
      })
      .catch((e) => {
        console.error("Issues while answering call : " + e);
      });
  }

  releaseLocalVideoStream(stream?: MediaStream) {
    console.log(Webrtc.LOG_PREFIX, "releaseLocalVideoStream");

    // Video stream could be on two places, the ui video ref and peerconnection, make sure to close both otherwise some browsers like safari will keep the camera on if one ref is still valid
    stream
      ?.getTracks()
      .filter((track) => track.kind === "video")
      .forEach((track) => track.stop());

    if (this.peerConnection !== undefined) {
      const rtcRtpList = this.peerConnection.getSenders();
      console.log("rtcRtpList", rtcRtpList);
      this.releaseVideoStream(rtcRtpList);
    }
  }

  private releaseVideoStream(rtcRtpList) {
    console.log(Webrtc.LOG_PREFIX, "releaseVideoStream");
    rtcRtpList
      .filter((current) => current.track?.kind === "video")
      .forEach((current) => {
        try {
          current.track?.stop();
        } catch (e) {
          console.error(
            Webrtc.LOG_PREFIX,
            "releaseVideoStream: error stopping sender track. Details: ",
            e
          );
        }
      });
  }
  private releaseRemoteVideoStream() {
    console.log(Webrtc.LOG_PREFIX, "releaseRemoteVideoStream");

    if (this.peerConnection !== undefined) {
      this.releaseVideoStream(this.peerConnection.getReceivers());
    }
  }

  private stopClose() {
    console.log(Webrtc.LOG_PREFIX, "stopClose");
    if (
      this.peerConnection !== undefined &&
      this.peerConnection.connectionState !== "closed"
    ) {
      this.peerConnection.getSenders().forEach((sender) => {
        try {
          sender.track?.stop();
        } catch (e) {
          console.error("Error stopping sender track. Details: ", e);
        }
      });
      this.peerConnection.getReceivers().forEach((receiver) => {
        try {
          receiver.track?.stop();
        } catch (e) {
          console.error("Error stopping receiver track. Details: ", e);
        }
      });
      this.peerConnection.getTransceivers().forEach((transceiver) => {
        try {
          transceiver.stop();
        } catch (e) {
          console.error("Error stopping transceiver. Details: ", e);
        }
      });

      try {
        this.peerConnection.close();
      } catch (e) {
        console.error("Error closing peer connection. Details: ", e);
      }
    } else {
      console.log("PeerConnection is already closed or undefined.");
    }

    if (this.statInterval) clearInterval(this.statInterval);
    this.statInterval = null;
  }

  setMediaStream(stream: MediaStream | undefined) {
    if (stream) {
      this.answerCall(stream);
    }
  }

  acceptRemoveRemoteVideo(
    autoDowngrade: boolean,
    stream: MediaStream | undefined
  ) {
    if (autoDowngrade) {
      this.releaseLocalVideoStream();
    }

    this.releaseRemoteVideoStream();

    if (stream) {
      this.setMediaStream(stream);
    }
  }

  public async hangupCall(rejectCode: Number = -1) {
    this.stopClose();
    releaseVideoCallStream();
    releaseAudioStream();
    const user = getLocalUser();
    if (!user) {
      console.error("No user logged in");
      return;
    }

    const acsConfig = await getConfig();
    if (!acsConfig) {
      console.error("No config");
      return;
    }

    // maybe reentrace e.g. multi-dev , reject -> accept -> wrtc_bye
    if (
      this.callId !== "" &&
      this.callState !== CALL_STATE.NoCall &&
      this.callState !== CALL_STATE.Reject
    ) {
      fetch(
        new URL(
          `/webrtc/v1/${cleanPhoneNumber(
            user
          )}/wrtc?access_token=${getLocalAccessToken()}`,
          baseWebGwUrl
        ),
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            method: "wrtc_bye",
            callid: this.callId,
            calluri: await formatPhoneNumber(this.remoteNumber, "SIP"),
            rejectCode,
          }),
        }
      ).catch((e) => console.error("Webrtc: hangupCall: ", e));
    }

    this.voiceBotManager.stopLiveRecording();
    this.voiceBotManager.stopCallTranscription(this.callId);

    this.updateCallState(CALL_STATE.NoCall);
  }

  public muteCall(mute: boolean) {
    if (this.peerConnection !== undefined) {
      this.peerConnection.getSenders().forEach((sender) => {
        if (sender.track && sender.track.kind === "audio") {
          sender.track.enabled = !mute;
        }
      });

      return true;
    } else {
      return false;
    }
  }

  private onNegotiationNeeded(event: any) {
    console.log("----- onNegotationNeeded");
    // console.log(event);
    console.log("local SDP:", event.srcElement?.localDescription);
    if (event.srcElement?.localDescription === null) {
      this.peerConnection
        .createOffer()
        .then((offer: RTCSessionDescriptionInit) => {
          let Sdp = offer.sdp;
          let sdpLines = Sdp!.split("\r\n");
          let sdpLines2: string[] = [];

          for (let i = 0; i < sdpLines.length; ++i) {
            if (
              sdpLines[i].indexOf("rtx") > 0 ||
              sdpLines[i].indexOf("apt") > 0
            ) {
              //ignore
            } else {
              sdpLines2.push(sdpLines[i]);
            }
          }
          let sdp = sdpLines2.join("\r\n");

          sdp = this.removeLinesFromSdp(sdp!);
          const localOffer = new RTCSessionDescription({
            type: offer.type,
            sdp: sdp,
          });
          this.peerConnection.setLocalDescription(localOffer);
          this.browserSupportsCvo ||=
            Sdp?.match(/a=extmap:.*urn:3gpp:video-orientation/g) !== null;
        });
    }
  }

  private async onTrack(event: any) {
    //RTCTrackEvent
    console.log(
      `<== kind:${event.track.kind}, id:${event.track.id}, contentHint:${event.track.contentHint}`
    );
    if (event.track.kind === "video") {
      const stream = new MediaStream();
      event.streams[0].getVideoTracks().forEach((t) => {
        t.contentHint = "motion";
      });
      stream.addTrack(event.streams[0].getVideoTracks()[0]);
      console.log("videoRef -> ", this.videoRef);
      (this.videoRef.current as HTMLVideoElement).srcObject = stream;
      await (this.videoRef.current as HTMLVideoElement)
        .play()
        .catch((error: any) => {
          console.error("Error playing video:", error);
        });
      // TODO: SEND THAT STREAM ON A REAL VIDEO ELEMENT
    } else if (event.track.kind === "audio") {
      const stream = new MediaStream();
      stream.addTrack(event.streams[0].getAudioTracks()[0]);
      this.setAudioRef(new Audio());
      this.audioRef.srcObject = stream;
      if (stream) {
        this.voiceBotManager.createLiveVoiceBot(stream, this.callId);
        this.voiceBotManager.createCallVoiceBot(
          this.microphoneAudioMedia,
          stream,
          this.callId
        );
      }
      await this.audioRef.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    }
    this.updateCallState(CALL_STATE.Active);
  }

  private onConnectionStateChange(event: any) {
    console.log(
      "---- onConnectionStateChange: " + event.currentTarget?.connectionState
    );
    if (
      event.currentTarget?.connectionState === "disconnected" &&
      this.callState !== "NoCall"
    ) {
      let nSec = 2;
      console.log("Stop the call in " + nSec);
      this.hangupCallTimer = setTimeout(() => {
        this.hangupCall();
      }, nSec * 1000);
      if (this.statInterval !== null) clearInterval(this.statInterval);
      this.statInterval = null;
    }

    if (event.currentTarget?.connectionState === "connected") {
      if (this.hangupCallTimer !== null) {
        clearTimeout(this.hangupCallTimer);
        this.hangupCallTimer = null;
      }
      this.setEncoderParams();
      this.statInterval = setInterval(() => {
        this.peerConnection?.getStats().then((stats: RTCStatsReport) => {
          if (this.onStatUpdated) this.onStatUpdated(stats);
        });
      }, 1000);
    }
  }

  private onIceConnectionStateChange(event: any) {
    console.log(
      "---- onIceConnectionStateChange : " +
        event.currentTarget?.iceConnectionState
    );
  }

  private onIceGatheringStateChange(event: any) {
    console.log(
      "---- onIceGatheringStateChange : " + event?.target?.iceGatheringState
    );
    if (event?.target?.iceGatheringState === "complete") {
      console.log("Done gathering ALL candidates, add them");
      this.finalizeSdp();
    }
  }

  public handleIncomingNotification(
    msg:
      | WebRTCPauseNotification
      | WebRTCStatusUpdateNotification
      | WebRTCAnswerNotification
      | WebRTCCVONotification
      | WebRTCRingingNotification
  ) {
    // {"notificationList":{"summitCallNotification":{"callid":"7231vxSxsjZN9B4OpDkybSV2w21","calluri":"sip:+15145550151@erl.rcs.st","method":"wrtc_ringing"}}}
    // {"notificationList":{"summitCallNotification":{"callid":"7231vxSxsjZN9B4OpDkybSV2w21","calluri":"sip:+15145550151@erl.rcs.st","method":"wrtc_answer","sdp":"v=0\r\no=- 1631908730557 1631908741064 IN IP4 192.168.1.56\r\ns=-\r\nc=IN IP4 64.254.226.151\r\nt=0 0\r\nm=audio 15016 UDP/TLS/RTP/SAVPF 0 126\r\nc=IN IP4 64.254.226.151\r\nb=AS:80\r\nb=RS:640\r\nb=RR:640\r\na=sendrecv\r\na=ptime:20\r\na=maxptime:240\r\na=rtpmap:0 PCMU/8000\r\na=rtpmap:126 telephone-event/8000\r\na=ice-pwd:q9wjp376bmoofkvuso61pm\r\na=ice-ufrag:mxk0\r\na=setup:passive\r\na=fingerprint:sha-256 89:3C:1F:74:87:3C:A0:48:0C:5B:00:F5:F1:E1:F3:93:3A:27:F1:32:D7:5E:B2:4C:F9:BD:B5:BC:E0:D1:A4:4B\r\na=rtcp-mux\r\na=candidate:0 1 UDP  64.254.226.151 15016 typ host\r\nm=video 15018 UDP/TLS/RTP/SAVPF 102 114 116\r\nc=IN IP4 64.254.226.151\r\nb=RS:640\r\nb=RR:640\r\na=sendrecv\r\na=rtpmap:116 ulpfec/90000\r\na=rtpmap:114 red/90000\r\na=rtpmap:102 H264/90000\r\na=rtcp-fb:* nack pli\r\na=rtcp-fb:* nack\r\na=rtcp-fb:* ccm fir\r\na=fmtp:102 profile-level-id=42e01e; packetization-mode=1; level-asymmetry-allowed=1\r\na=ice-pwd:5iv8l8ewozg37zqf8x0xuv\r\na=ice-ufrag:0j7m\r\na=setup:passive\r\na=fingerprint:sha-256 89:3C:1F:74:87:3C:A0:48:0C:5B:00:F5:F1:E1:F3:93:3A:27:F1:32:D7:5E:B2:4C:F9:BD:B5:BC:E0:D1:A4:4B\r\na=rtcp-mux\r\na=candidate:0 1 UDP  64.254.226.151 15018 typ host\r\n"}}}

    if ("callid" in msg && this.callId && msg.callid !== this.callId) {
      console.log(
        "received a webrtc notification for ",
        msg.callid,
        " which is not the current one ",
        this.callId,
        " ignoring.",
        msg
      );
      return;
    }

    console.log("received a webrtc notification:", msg);
    if (msg.method === "wrtc_ringing") {
      const msg2 = msg as WebRTCRingingNotification;
      this.callId = msg2.callid;
      console.log("Wait for remote to accept call");
    } else if (msg.method === "wrtc_answer") {
      const answerOrCall = msg as WebRTCAnswerNotification;
      console.log(
        "Remote accepted call, we need to parse their SDP, setting callid to ",
        answerOrCall.callid
      );
      this.callId = answerOrCall.callid;
      if (this.peerConnection !== undefined) {
        let answer = answerOrCall.sdp,
          offer = this.peerConnection.localDescription?.sdp ?? "";
        let sdpMediaIndexes = [
          offer.indexOf("m=video "),
          offer.indexOf("m=audio "),
          answer.indexOf("m=video "),
          answer.indexOf("m=audio "),
        ];
        if (
          sdpMediaIndexes[0] > 0 &&
          sdpMediaIndexes[1] > 0 &&
          sdpMediaIndexes[2] > 0 &&
          sdpMediaIndexes[3] > 0
        ) {
          if (
            sdpMediaIndexes[0] < sdpMediaIndexes[1] &&
            sdpMediaIndexes[2] > sdpMediaIndexes[3]
          ) {
            answer =
              answer.substring(0, sdpMediaIndexes[3]) +
              answer.substring(sdpMediaIndexes[2], answer.length) +
              answer.substring(sdpMediaIndexes[3], sdpMediaIndexes[2]);
            console.log(
              "change order as offer have video before audio but not same in answer:" +
                answer
            );
          } else {
            console.log(
              "keep as is as " +
                [
                  sdpMediaIndexes[0] < sdpMediaIndexes[1],
                  sdpMediaIndexes[2] > sdpMediaIndexes[3],
                ]
            );
          }
        }

        const sdp = this.removeLinesFromSdp(answer);

        const rtcSessionDescription = new RTCSessionDescription({
          type: "answer",
          sdp: sdp,
        });
        this.peerConnection.setRemoteDescription(rtcSessionDescription);

        // We release the local video and camera here in case remote answered without it
        if (!Webrtc.REGEX_VIDEO_ON_SDP.test(answerOrCall.sdp)) {
          this.releaseLocalVideoStream();
        }
      }
    } else if (msg.method === "wrtc_call") {
      // callid: "un-pns-DGHkJ2t85R8M83A3rPWyCYB8Am6"
      // calluri: "sip:+15145550151@erl.rcs.st"
      const answerOrCall = msg as WebRTCAnswerNotification;
      this.updateCallState(
        CALL_STATE.Incoming,
        answerOrCall.calluri,
        answerOrCall.callid,
        answerOrCall.sdp
      );
      //TODO: Here we need to provide the user with a button to accept the call, fow now I am auto-accepting for testing purposes
      // For now, we can accept as video or audio, this means you need to have the right constraint.
      // this.answerCall(VIDEO_CALL_CONSTRAINTS);
    } else if (msg.method === "wrtc_reject") {
      console.log("Got a reject, call canceled");
      this.hangupCall();
      this.updateCallState(CALL_STATE.Reject);
    } else if (msg.method === "wrtc_bye") {
      this.hangupCall();
    } else if (msg.method === "wrtc_cvo") {
      if (!this.browserSupportsCvo) {
        const cvoMsg = msg as WebRTCCVONotification;
        this.handleCvo(cvoMsg.cvocode);
      }
    } else if (msg.method === "wrtc_pause") {
      const pauseMsg = msg as WebRTCPauseNotification;
      this.handlePause(pauseMsg.pause);
    } else if (msg.method === "no_call") {
      this.hangupCall();
    }
  }

  public handlePause(pause: boolean) {
    this.onPause(pause);
  }

  public handleCvo(cvo: number): any {
    switch (cvo) {
      case 0:
        this.transformVideo = 0;
        break;
      case 1:
        this.transformVideo = 90;
        break;
      case 2:
        this.transformVideo = 180;
        break;
      case 3:
        this.transformVideo = 270;
        break;
    }
    console.log(`Switch video rotation to ${cvo}, ${this.transformVideo}`);
    if (this.onCvoChanged !== undefined) {
      this.onCvoChanged(this.transformVideo);
    }
  }

  public updateCallState(
    newState: CALL_STATE,
    remote: string | undefined = undefined,
    callid: string | undefined = undefined,
    sdp: string | undefined = undefined
  ): any {
    let info = "";

    if (remote !== undefined) {
      info += ", remote: " + remote;
      this.remoteNumber = remote;
    } else if (newState === CALL_STATE.NoCall) {
      this.remoteNumber = "";
      this.transformVideo = 0;
    }

    if (callid !== undefined) {
      info += ", callid: " + callid;
      this.callId = callid;
    }

    let RemoteDesc = "";
    try {
      // peerConnection could be close, if it is, it will throw
      if (sdp !== undefined) {
        info += ", new SDP provided";
        if (newState === CALL_STATE.Incoming) {
          RemoteDesc = "";
          this.remoteSDP = sdp;
        }
      } else {
        RemoteDesc = this.peerConnection?.remoteDescription?.sdp ?? ""; //leftover from lasttime
        sdp = this.peerConnection?.localDescription?.sdp;
      }
    } catch (e) {
      //ignore
    }
    console.log(
      Webrtc.LOG_PREFIX,
      `updateCallState: Current call with ${this.remoteNumber} changed state ${this.callState} -> ${newState}${info}`
    );

    const outgoingCallAccepted =
      this.callState === CALL_STATE.Outgoing && newState === CALL_STATE.Active;
    this.callState = newState;

    const isVideo = Webrtc.REGEX_VIDEO_ON_SDP.test(sdp ?? "");
    // This is needed to know if remote accepted video or not
    const peerIncludesVideo = Webrtc.REGEX_VIDEO_ON_SDP.test(RemoteDesc);

    console.log(
      Webrtc.LOG_PREFIX,
      ", updateCallState: sdp includes video ",
      isVideo
    );
    console.log(
      Webrtc.LOG_PREFIX,
      ", updateCallState: participants has video ",
      peerIncludesVideo
    );

    if (newState === CALL_STATE.NoCall) {
      // In case the transcript was stopped before the call had ended.
      this.voiceBotManager.callEnded(this.callId);
      console.log("Clearing call info");
      this.callId = "";
      this.microphoneAudioMedia = undefined;
    }

    if (this.onCallStateChange !== undefined) {
      this.onCallStateChange(
        this.callState,
        this.remoteNumber,
        // Remote accepted the call, only use video based on the peer info
        outgoingCallAccepted ? peerIncludesVideo : isVideo || peerIncludesVideo
      );
    }
    if (this.onCvoChanged !== undefined) {
      this.onCvoChanged(this.transformVideo);
    }

    if (this.callDurationTimer !== null) {
      clearTimeout(this.callDurationTimer);
      this.callDurationTimer = null;
    }
  }

  private removeLinesFromSdp(sdp: string) {
    let sdpLines = sdp.split("\r\n");
    let sdpLines2: string[] = [];

    for (let i = 0; i < sdpLines.length; ++i) {
      if (sdpLines[i].indexOf("b=AS") > -1) {
        // ignore
      } else {
        sdpLines2.push(sdpLines[i]);
      }
    }
    const sdp2 = sdpLines2.join("\r\n");
    return sdp2;
  }

  private setEncoderParams() {
    // In modern browsers, use RTCRtpSender.setParameters to change bandwidth without
    // (local) renegotiation. Note that this will be within the envelope of
    // the initial maximum bandwidth negotiated via SDP.
    if (
      (adapter.browserDetails.browser === "chrome" ||
        adapter.browserDetails.browser === "safari" ||
        adapter.browserDetails.browser === "firefox") &&
      "RTCRtpSender" in window &&
      "setParameters" in window.RTCRtpSender.prototype
    ) {
      this.peerConnection.getSenders().forEach((sender) => {
        if (sender.track?.kind === "video") {
          console.log(sender);
          const parameters = sender.getParameters();
          if (!parameters.encodings) {
            parameters.encodings = [{}];
          }
          parameters.encodings[0].maxBitrate = TARGET_BITRATE * 1000;
          parameters.encodings[0].maxFramerate = TARGET_FRAMERATE;
          parameters.encodings[0].networkPriority = "high";
          parameters.encodings[0].priority = "high";
          parameters.degradationPreference = "maintain-resolution"; //Deprecated
          sender.track.contentHint = "motion";

          console.log(
            "Setting contentHint = 'motion' , New encoder parameters: ",
            parameters
          );
          sender
            .setParameters(parameters)
            .then(() => {
              console.log(
                "Successfully set new encoding params: ",
                JSON.stringify(parameters.encodings[0])
              );
            })
            .catch((e) => console.error(e));

          console.log(sender);
        }
      });
    }
  }

  public receivedCallLogNmsObject(nmsObject: NmsMessage) {
    console.log(
      "Recorder: Received NMS object with CallID",
      nmsObject["imdn.Message-ID"]
    );
    if (nmsObject["Content-Type"] === "application/vnd.call-history+json") {
      console.log(
        "Found the right NMSObject, using objectID",
        nmsObject.ObjectId
      );
      updateCallsInDatabase([nmsObject["imdn.Message-ID"]]);
      this.transcriptSender.addCallHistoryNmsObject(nmsObject);
    }
  }
}
