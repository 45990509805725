import {
  WebGwContactList,
  wrapToWebGwContactList,
} from "../helpers/WebGwContact";
import { contactsQuery } from "../queries/contacts";
import { queryClient } from "../queryClient";

export function filterContactWithCaps(contactList?: WebGwContactList | null) {
  if (!contactList) {
    return new WebGwContactList();
  }

  return contactList.filter((contact) => contact.isVerse) as WebGwContactList;
}

type QueryReturn = Awaited<ReturnType<typeof contactsQuery.queryFn>>;

export function getLoadedContacts() {
  return wrapToWebGwContactList(
    queryClient.getQueryData<QueryReturn>(contactsQuery.queryKey)
  );
}

export async function getContactsAsync() {
  return wrapToWebGwContactList(await queryClient.fetchQuery(contactsQuery));
}

export function setLoadedContacts(data: WebGwContactList) {
  return queryClient.setQueryData(contactsQuery.queryKey, data);
}

export async function refreshContacts() {
  await queryClient.invalidateQueries({ queryKey: contactsQuery.queryKey });
}
