import * as Sentry from "@sentry/react";
import "iterator-helpers-polyfill";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { setUserLocationCountry } from "./utils/helpers/localstorage";
import { getUserLocationCountry } from "./utils/helpers/location";
import "./utils/logs/logOptions";

if (import.meta.env.VITE_CHANGE_ID && import.meta.env.VITE_GIT_HASH) {
  console.debug(`Git hash: ${import.meta.env.VITE_GIT_HASH}`);
  console.debug(`Change-Id: ${import.meta.env.VITE_CHANGE_ID}`);

  console.debug(`DOMAIN: ${window._env_.DOMAIN}`);
}

Sentry.init({
  dsn: window._env_.SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https?:\/\/(www\.)?versemessages\.com\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

getUserLocationCountry().then((country) => {
  setUserLocationCountry(country);
});

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
