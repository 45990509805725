import { useMemo } from "react";
import { useSnapshot } from "valtio";
import { callsState } from "../calls/callState";

export function useUnreadCallsCount() {
  const { calls } = useSnapshot(callsState);

  return useMemo(() => {
    return (
      calls
        .values()
        // ignore valls that are read or calls that are outgoing
        .filter((c) => !c.isRead && c.callLogPayload.type !== "outgoing")
        .count()
    );
  }, [calls]);
}
